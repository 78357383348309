import React from 'react';
import UserInfo from './UserInfo';
import { Routes } from './Router';
import { UserContext } from '../contexts/User';
import Dropdown from './ui/Dropdown';
import { Link } from 'react-router-dom';

/**
 * App top nav bar.
 */
export default function NavTop() {
    const navbar = "flex shadow-sm bg-gray-300 rounded m-1";
    const item = "rounded bg-gray-200 px-3 py-1 m-1 hover:bg-gray-50 text-gray-800";
    const nonessential = "rounded p-1 m-1 hidden lg:block text-gray-800";
    const { user } = React.useContext(UserContext);
    const year = new Date().getFullYear();
    return (
        <div className="flex flex-wrap justify-center text-sm ss-nav-top" >
            <nav className="flex  flex-wrap justify-center">
                <ul className={navbar} >
                    <li className={nonessential}><a href="mailto:VentSimCC@gmail.com?subject=VentSim Feedback">© {year} Sami Safadi, MD</a></li>
                </ul>
                <ul className={navbar} >
                    <li className={item}><Link to={Routes.opensim}>Interactive Sim</Link></li>
                    <li>
                        <Dropdown title='Waveforms'>
                            <Link to={Routes.vc}>Volume-Control</Link>
                            <Link to={Routes.pc}>Pressure-Control</Link>
                            <Link to={Routes.vt}>Volume-Targeted</Link>
                        </Dropdown>
                    </li>
                    <li>
                        <Dropdown title='PV & FV Loops'>
                            <Link to={Routes.vcloop}>Volume-Control</Link>
                            <Link to={Routes.pcloop}>Pressure-Control</Link>
                        </Dropdown>
                    </li>
                    <li>
                        <Dropdown title='Compare'>
                            <Link to={Routes.vccompare}>Two VC</Link>
                            <Link to={Routes.pccompare}>Two PC</Link>
                            <Link to={Routes.vtcompare}>Two VT</Link>
                            <Link to={Routes.vcpccompare}>VC &#38; PC</Link>
                            <Link to={Routes.vcvtcompare}>VC &#38; VT</Link>
                            <Link to={Routes.pcvtcompare}>PC &#38; VT</Link>
                            <Link to={Routes.vcpcvtcompare}>VC | PC | VT</Link>
                        </Dropdown>
                    </li>
                    <li>
                        <Dropdown title='Other'>
                            <Link to={Routes.otherps}>Pressure-Support (PS/CPAP)</Link>
                            <Link to={Routes.otheraprv}>Airway Pressure Release (APRV)</Link>
                            <Link to={Routes.otherinflection}>ACVC with Non-Linear Lung Compliance</Link>
                        </Dropdown>
                    </li>
                    {user && user.email === "samisaf@gmail.com" ?
                        <li>
                            <Dropdown title='Admin'>
                                <Link to={Routes.analytics}>Analytics</Link>
                                <Link to={Routes.inviteuser}>Add User</Link>
                            </Dropdown></li>
                        : <li><></></li>}
                    <li className={nonessential}>Reset</li>
                    <li className={item}>
                        <Link to={Routes.reset}>↺</Link></li>
                </ul>

                <ul className={'flex shadow-sm bg-gray-300 rounded m-1'}>
                    <li className={"rounded p-1 m-1"}>
                        <UserInfo />
                    </li >
                </ul>
            </nav>
        </div>
    );
}