import React from 'react';
import SimVT from './SimVT'
import { SettingsContext } from '../contexts/Settings';

export default function Compare2VT() {
    const { toggleAutoScale } = React.useContext(SettingsContext);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => toggleAutoScale(false), []);

    return (
        <div className={"grid grid-cols-2"}>
            <SimVT widthFactor={2} />
            <SimVT widthFactor={2} />
        </div>
    );
}
