import React from 'react';

import {
  LineChart,
  Tooltip,
  Legend,
  Line,
  XAxis,
  YAxis,
  CartesianGrid
} from "recharts";
import { AxisDomain } from 'recharts/types/util/types';

import { ChartProps, formatterX, formatterY } from './Chart';

/**
 * Generate waveforms with time on x-axis, and pressure | flow | volume on y-axis
 * based on the data provided.
 */
const Waveform: React.FC<ChartProps> = (
  { df, x, ys=["Paw"], strokeWidth, colors, autoScale = true, yMin = 0, yMax = 0, ncol = 1, nrow = 3.0 }) => {
  // Chart scales
  const xDomain: AxisDomain = [0, 'dataMax'];
  const yDomain = autoScale ? ['auto', 'auto'] : [Math.round(yMin), Math.round(yMax)]
  const margins = { top: 1, right: 1, left: 1, bottom: 1 };

  // Chart width and height changes based on browser's dimension
  const widthFactor = 1.05;
  const heightFactor = 1.1;
  const [width, setWidth] = React.useState(window.innerWidth / widthFactor / ncol);
  const [height, setHeight] = React.useState(window.outerHeight / heightFactor / nrow);

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth / widthFactor / ncol);
      setHeight(window.outerHeight / heightFactor / nrow);
    })
  }, [ncol, nrow]);


  return (
    <div className={"ventsim-waveform"}>
      <LineChart width={width} height={height} data={df} margin={margins} layout={"horizontal"} syncId="waveform">
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={x} type="number" domain={xDomain} />
        <YAxis width={35} type="number" domain={yDomain} />
        <Tooltip formatter={formatterY} labelFormatter={(value) => formatterX(value, x)} />
        <Legend align="right" verticalAlign="top" height={1} />
        {ys.map((y, index) => (
          <Line
            key={index}
            type="monotone"
            dataKey={y}
            stroke={colors ? colors[index] : 'black'}
            dot={false}
            strokeWidth={strokeWidth}
          />
        ))}
      </LineChart>
    </div>
  );
}

export default Waveform;